<template>
  <div>
    <!-- v-if="showExam" -->
    <div class="viewOnlineExam">
      <v-row v-if="showExam">
        <v-col cols="12">
          <div class="title">
            <h1>{{ homeWorkData.class_name }}</h1>
          </div>
          <div class="title">
            <h2 class="main-color">
              {{ homeWorkData.exam_name }}
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="showExam">
        <v-col cols="11" md="11" class="info-list">
          <ul class="inline">
            <!-- <li><strong> Date: </strong>{{ homeWorkData.exam_date }}</li>
            <li>
              <strong> Duration: </strong>{{ homeWorkData.duration_hour }} :
              {{ homeWorkData.duration_min }}
            </li>
            <li>
              <strong> Academic Period:</strong> {{ homeWorkData.academicYear }}
            </li>
            <li><strong> Qualifier:</strong> {{ homeWorkData.qualifier }}</li> -->
            <li>
              <strong> {{ $t("Marks") }}:</strong> {{ homeWorkData.exam_mark }}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11" md="11" class="hr-div">
          <hr />
        </v-col>
      </v-row>

      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col
            cols="11"
            sm="11"
            class="sectionBody"
            v-for="(section, index) in homeWorkData.sections"
            :key="index"
          >
            <v-card>
              <v-row>
                <v-col cols="12" sm="12">
                  <p class="section-title main-color" style="float: left">
                    {{ section.section_title }}
                  </p>
                  <p style="float: right" class="section-title main-color">
                    {{ section.section_total_marks }} {{ $t("Marks") }}
                  </p>
                </v-col>
              </v-row>

              <v-row
                v-for="(question, qindex) in section['questions']"
                :key="qindex"
                class="question-div"
              >
                <v-col cols="12" sm="12">
                  <!-- :style="
                    qindex != 0
                      ? 'padding-top: 0; margin-top:-2%;'
                      : 'margin-top:-1%;'
                  " -->
                  <v-row>
                    <v-col cols="6" sm="6" class="pb-0">
                      <p class="text-left question mb-0">
                        <strong v-html="question.question"> </strong>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" style="padding-top: 0;">
                  <v-textarea
                    v-if="question.type == 'essay'"
                    :rules="[
                      required,
                      checkMinMax(
                        question.min,
                        question.max,
                        question.type,
                        question.answer
                      ),
                    ]"
                    v-model="question.answer"
                    solo
                  ></v-textarea>

                  <div class="attachFile mb-4" v-if="question.type == 'essay'">
                    <input
                      type="file"
                      class="myFileBrowser"
                      @change="onFileSelected($event, index, qindex, question)"
                      title="Attach File"
                      ref="attachment"
                    />
                    <!-- ref="attachment" -->
                    <v-btn title="Attach File " style="cursor: pointer" icon>
                      <v-icon class="main-color">attach_file</v-icon>
                    </v-btn>
                    <span> Select File</span>
                    <!-- v-if="
                        fileNames.length == 0 ||
                          (fileNames[index] &&
                            fileNames[index].length > 0 &&
                            fileNames[index][qindex] == undefined)
                      " -->
                    <span
                      v-if="
                        fileNames &&
                          fileNames[index] &&
                          fileNames[index][qindex]
                      "
                    >
                      <!-- -->
                      <v-chip> {{ fileNames[index][qindex] }}</v-chip>
                    </span>
                    <p class="error--text" v-if="fileSizeErrors[qindex]">
                      {{ fileSizeErrors[qindex] }}
                    </p>
                    <span v-if="fileUploaded"></span>
                  </div>

                  <v-radio-group
                    v-model="question.answer"
                    :rules="[required]"
                    v-if="question.type == 'tof' || question.type == 'mcq'"
                    row
                  >
                    <v-radio
                      v-for="(option, Oindex) in question.options"
                      :key="Oindex"
                      :label="option.value"
                      :value="option.value"
                    >
                      <template slot="label">
                        <p
                          class="answer-img-container"
                          v-html="$t(option.value)"
                        ></p>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <div v-if="question.type == 'join'">
                    <v-row
                      v-for="(join, Jindex) in question.joins"
                      :key="Jindex"
                    >
                      <v-col cols="4">
                        <p
                          :hidden="
                            join.join_question == '' ||
                              join.join_question == null
                          "
                        >
                          {{ join.join_question_number }} .
                          {{ join.join_question }}
                        </p>
                      </v-col>
                      <v-col cols="4">
                        <!-- :hidden="
                            join.join_question == '' ||
                              join.join_question == null
                          " -->
                        <div v-if="join.join_question">
                          <v-select
                            :items="join.all_answers_items"
                            solo
                            v-model="join.answer"
                            :rules="[required]"
                            @change="FilterCorrectAnswer(qindex, index, Jindex)"
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <p>
                          {{ join.join_answer_number }} . {{ join.join_answer }}
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- <hr /> -->
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="11" sm="11" v-if="showExam">
            <div class="btns text-right">
              <v-btn
                class="ui-btn submit"
                :loading="loading"
                :disabled="loading"
                @click.prevent="submitData"
                large
              >
                <!-- @click.prevent="" -->
                {{ $t("Submit Answers") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="viewOnlineExam" v-if="!showExam">
      <v-row>
        <v-col cols="8" md="8">
          <v-alert class="info_alert">
            {{ showExamErrorText }}
          </v-alert>
        </v-col>
        <v-col cols="4" md="4">
          <v-icon large :title="$t('Go Back')" @click="goToviewMode()"
            >close</v-icon
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "StudentHomeWorkView",
  data() {
    return {
      required: (v) => !!v || this.$i18n.t("This field is required"),
      valid: false,
      homeWorkData: {},
      homeWorkId: "",
      // duration: 10,
      showExam: 0,
      showExamErrorText: "",
      fileNames: [],
      fileSizeErrors: [],
      fileUploaded: false,
      loading: false,
    };
  },

  methods: {
    checkMinMax(min, max, type, str) {
      if (type == "essay") {
        if (min && str.length < min) {
          return "Answer should be at least " + min + " letters";
        } else if (max && str.length > max) {
          return "Answer shouldn't exceed " + max + " letters";
        } else {
          return true;
        }
      }
      return true;
    },
    goToviewMode() {
      this.$router.push(
        {
          path: "/",
        },
        () => {}
      );
    },
    FilterCorrectAnswer(questionIndex, sectionIndex, joinIndex) {
      let answer = this.homeWorkData.sections[sectionIndex]["questions"][
        questionIndex
      ]["joins"][joinIndex]["answer"];
      let empty_all_item_answers;
      empty_all_item_answers = [
        ...this.homeWorkData.sections[sectionIndex]["questions"][questionIndex][
          "joins"
        ][joinIndex]["all_answers_items"],
      ];
      let correct_answers_array = [];
      let question_numbers_array = [];
      this.homeWorkData.sections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        correct_answers_array.push(join.answer);
        question_numbers_array.push(join.join_answer_number);

        if (join.answer != answer && answer != "") {
          console.log(join);
          let all_answers_items = [];
          for (let index = 0; index < join.all_answers_items.length; index++) {
            if (join.all_answers_items[index] != answer) {
              all_answers_items.push(join.all_answers_items[index]);
            }
          }

          join.all_answers_items = [];
          join.all_answers_items = [...all_answers_items];
        } else if (answer == "") {
          empty_all_item_answers.forEach((item) => {
            let answer_found = join.all_answers_items.includes(item);
            console.log(answer_found);
            if (!answer_found) {
              join.all_answers_items.push(item);
            }
          });
        }
      });
      let questions_numbers_not_in_correct_answers = [];
      question_numbers_array.forEach((number) => {
        if (!correct_answers_array.includes(number)) {
          questions_numbers_not_in_correct_answers.push(number);
        }
      });

      this.homeWorkData.sections[sectionIndex]["questions"][questionIndex][
        "joins"
      ].forEach((join) => {
        questions_numbers_not_in_correct_answers.forEach((number) => {
          if (!join.all_answers_items.includes(number)) {
            join.all_answers_items.push(number);
          }
        });
      });
    },
    submitData() {
      if (this.valid == true) {
        if (this.checkErrorOnFile() == false) {
          this.loading = true;
          axios
            .post(
              this.getApiUrl +
                "/homework/studentAssignmentStore/" +
                this.homeWorkId,
              this.homeWorkData,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              this.$router.push(
                {
                  path: "/",
                },
                () => {}
              );
            });
        }
      } else {
        this.$refs.form.validate();
      }
    },
    gethomeWorkData() {
      axios
        .get(
          this.getApiUrl + "/homework/getAssignmentStudent/" + this.homeWorkId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error) {
            this.showExam = false;
            this.showExamErrorText = response.data.status.message;
          } else {
            this.showExam = true;
            this.homeWorkData = response.data.data;
          }
        });
    },
    onFileSelected(event, sectionIndex, qindex, question) {
      if (!this.fileNames[sectionIndex]) {
        this.fileNames[sectionIndex] = [];
      }

      if (this.fileNames[sectionIndex].length - 1 < qindex) {
        for (let index = 0; index < qindex; index++) {
          this.fileNames[sectionIndex].push(undefined);
        }
      }
      this.fileNames[sectionIndex].splice(
        qindex,
        1,
        event.target.files[0].name
      );
      let fileSize = event.target.files[0].size / 1024 / 1024;
      this.fileUploaded = true;
      if (fileSize <= 50) {
        this.uploadAttachment(event.target.files[0], question);

        this.fileSizeErrors[qindex] = "";
      } else {
        this.fileSizeErrors[qindex] = "Attachment size shouldn’t exceed 50M";
        this.fileUploaded = false;
      }
    },
    uploadAttachment(attach, question) {
      const fd = new FormData();
      fd.append("file", attach);
      this.loading = true;
      axios
        .post(this.getApiUrl + "/uploadFile", fd, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          question.file = response.data.data.url;
          this.fileUploaded = false;
          this.loading = false;
        });
    },
    checkErrorOnFile() {
      let FileError = false;
      if (this.fileSizeErrors.length > 0) {
        this.fileSizeErrors.forEach((item) => {
          if (item != undefined && item != "") {
            FileError = true;
          } else {
            FileError = false;
          }
        });
      }
      return FileError;
    },
  },
  mounted() {
    this.homeWorkId = this.$router.currentRoute.params.homeWorkId;
    this.gethomeWorkData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 20%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.question-div {
  // border: 1px solid #ddd;
  box-shadow: rgb(0 0 0 / 35%) 0px 2px 5px;
  margin-bottom: 5px;
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.exam-section {
  margin-bottom: 1rem;
}

.sectionInput {
  background-color: #eff3ff;
  padding: 25px 0px 25px 50px;
}
hr.hr-bold {
  border-top: 2px solid rgb(18 2 2 / 10%);
}

label {
  margin-top: 0 !important;
}
.clickable {
  cursor: pointer;
}
.questionbody {
  box-shadow: $box-shadow;
  padding-bottom: 2px !important;
  // margin-top: 2px;
  margin-bottom: 2px;
}
.addQuestion {
  padding: 10px;
  background-color: #eff3ff;
}

.addOption {
  padding: 5px;
  background-color: #eff3ff;
}
.tags {
}

.divider {
  height: 2rem;
  width: 100%;
  background-color: #eff3ff;
  margin: 0 !important;
  padding: 0 !important;
}

.displayDiv {
  display: block !important;
}
.hiddenDiv {
  display: none;
}
.loader img {
  margin-top: 50px;
  display: inline-block;
  margin-right: 2rem;
}

.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
</style>
