var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"viewOnlineExam"},[(_vm.showExam)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.homeWorkData.class_name))])]),_c('div',{staticClass:"title"},[_c('h2',{staticClass:"main-color"},[_vm._v(" "+_vm._s(_vm.homeWorkData.exam_name)+" ")])])])],1):_vm._e(),(_vm.showExam)?_c('v-row',[_c('v-col',{staticClass:"info-list",attrs:{"cols":"11","md":"11"}},[_c('ul',{staticClass:"inline"},[_c('li',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("Marks"))+":")]),_vm._v(" "+_vm._s(_vm.homeWorkData.exam_mark)+" ")])])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"hr-div",attrs:{"cols":"11","md":"11"}},[_c('hr')])],1),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',_vm._l((_vm.homeWorkData.sections),function(section,index){return _c('v-col',{key:index,staticClass:"sectionBody",attrs:{"cols":"11","sm":"11"}},[_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('p',{staticClass:"section-title main-color",staticStyle:{"float":"left"}},[_vm._v(" "+_vm._s(section.section_title)+" ")]),_c('p',{staticClass:"section-title main-color",staticStyle:{"float":"right"}},[_vm._v(" "+_vm._s(section.section_total_marks)+" "+_vm._s(_vm.$t("Marks"))+" ")])])],1),_vm._l((section['questions']),function(question,qindex){return _c('v-row',{key:qindex,staticClass:"question-div"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"6"}},[_c('p',{staticClass:"text-left question mb-0"},[_c('strong',{domProps:{"innerHTML":_vm._s(question.question)}})])])],1)],1),_c('v-col',{staticStyle:{"padding-top":"0"},attrs:{"cols":"12","sm":"12"}},[(question.type == 'essay')?_c('v-textarea',{attrs:{"rules":[
                    _vm.required,
                    _vm.checkMinMax(
                      question.min,
                      question.max,
                      question.type,
                      question.answer
                    ),
                  ],"solo":""},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}}):_vm._e(),(question.type == 'essay')?_c('div',{staticClass:"attachFile mb-4"},[_c('input',{ref:"attachment",refInFor:true,staticClass:"myFileBrowser",attrs:{"type":"file","title":"Attach File"},on:{"change":function($event){return _vm.onFileSelected($event, index, qindex, question)}}}),_c('v-btn',{staticStyle:{"cursor":"pointer"},attrs:{"title":"Attach File ","icon":""}},[_c('v-icon',{staticClass:"main-color"},[_vm._v("attach_file")])],1),_c('span',[_vm._v(" Select File")]),(
                      _vm.fileNames &&
                        _vm.fileNames[index] &&
                        _vm.fileNames[index][qindex]
                    )?_c('span',[_c('v-chip',[_vm._v(" "+_vm._s(_vm.fileNames[index][qindex]))])],1):_vm._e(),(_vm.fileSizeErrors[qindex])?_c('p',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.fileSizeErrors[qindex])+" ")]):_vm._e(),(_vm.fileUploaded)?_c('span'):_vm._e()],1):_vm._e(),(question.type == 'tof' || question.type == 'mcq')?_c('v-radio-group',{attrs:{"rules":[_vm.required],"row":""},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}},_vm._l((question.options),function(option,Oindex){return _c('v-radio',{key:Oindex,attrs:{"label":option.value,"value":option.value}},[_c('template',{slot:"label"},[_c('p',{staticClass:"answer-img-container",domProps:{"innerHTML":_vm._s(_vm.$t(option.value))}})])],2)}),1):_vm._e(),(question.type == 'join')?_c('div',_vm._l((question.joins),function(join,Jindex){return _c('v-row',{key:Jindex},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',{attrs:{"hidden":join.join_question == '' ||
                            join.join_question == null}},[_vm._v(" "+_vm._s(join.join_question_number)+" . "+_vm._s(join.join_question)+" ")])]),_c('v-col',{attrs:{"cols":"4"}},[(join.join_question)?_c('div',[_c('v-select',{attrs:{"items":join.all_answers_items,"solo":"","rules":[_vm.required]},on:{"change":function($event){return _vm.FilterCorrectAnswer(qindex, index, Jindex)}},model:{value:(join.answer),callback:function ($$v) {_vm.$set(join, "answer", $$v)},expression:"join.answer"}})],1):_vm._e()]),_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v(" "+_vm._s(join.join_answer_number)+" . "+_vm._s(join.join_answer)+" ")])])],1)}),1):_vm._e()],1)],1)})],2)],1)}),1),_c('v-row',[(_vm.showExam)?_c('v-col',{attrs:{"cols":"11","sm":"11"}},[_c('div',{staticClass:"btns text-right"},[_c('v-btn',{staticClass:"ui-btn submit",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"large":""},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Submit Answers"))+" ")])],1)]):_vm._e()],1)],1)],1),(!_vm.showExam)?_c('div',{staticClass:"viewOnlineExam"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8","md":"8"}},[_c('v-alert',{staticClass:"info_alert"},[_vm._v(" "+_vm._s(_vm.showExamErrorText)+" ")])],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-icon',{attrs:{"large":"","title":_vm.$t('Go Back')},on:{"click":function($event){return _vm.goToviewMode()}}},[_vm._v("close")])],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }